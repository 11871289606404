// import HeroTextArea from "./HeroTextArea";
// import Header from "./Header";
// import CoreMessage from "./CoreMessage";
// import WhatYouLearn from "./WhatYouLearn";
// import WhoFor from "./WhoFor";
// import HowProgramWorks from "./HowProgramWorks";
// import AboutTeam from "./AboutTeam";
// import WhatFolksAreSaying from "./WhatFolksAreSaying";
// import FAQ from "./FAQ.js";
// import ContactUs from "./ContactUs";

// export {Header, HeroTextArea, CoreMessage, WhatYouLearn, WhoFor, HowProgramWorks, AboutTeam, WhatFolksAreSaying, FAQ, ContactUs };

import React from 'react';
import { useState } from 'react';
import Header from "./Header"
import CoreMessage from "./CoreMessage"
import WhatYouLearn from "./WhatYouLearn"
import WhoFor from "./WhoFor"
import HowProgramWorks from "./HowProgramWorks"
import AboutTeam from "./AboutTeam"
import WhatFolksAreSaying from "./WhatFolksAreSaying"
import FAQ from "./FAQ"
import ContactUs from "./ContactUs"
import WorkedFor from "./WorkedFor"
import BookACall from "../../components/utils/BookACall";

export default function Homepage() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-[#000100] font-Raleway">
      <Header calendlyToggle={setIsOpen} />
      <CoreMessage />
      <WhatYouLearn />
      <WhoFor />
      <HowProgramWorks />
      <AboutTeam />
      <WorkedFor />
      <WhatFolksAreSaying />
      <BookACall
        isOpen={isOpen}
        calendlyToggle={setIsOpen}
      />
      <FAQ />
      <ContactUs calendlyToggle={setIsOpen} />
    </div>
  )
}
