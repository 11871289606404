import mercuryColor from "../../img/student-outcomes/companies/mercuryColor.png";
import googleColor from "../../img/student-outcomes/companies/googleColor.png";
import pagerDutyColor from "../../img/student-outcomes/companies/pagerdutyColor.png";
import erdosVenturesColor from "../../img/student-outcomes/companies/erdosventuresColor.png";
import unchainedPartnersColor from "../../img/student-outcomes/companies/unchainedpartnersColor.png";
import AristaColor from "../../img/student-outcomes/companies/aristaColor.png"
import woshi from "../../img/student-outcomes/companies/woshi.png";
import bambee from "../../img/student-outcomes/companies/bambee.png";
import ibm from "../../img/student-outcomes/companies/IBM.png";
import implentio from "../../img/student-outcomes/companies/Implentio.png";
import popular from "../../img/student-outcomes/companies/popular.png";
import litespace from "../../img/student-outcomes/companies/litespace.png";
import threeRm from "../../img/student-outcomes/companies/3rm.png";
import setori from "../../img/student-outcomes/companies/setori.png";
import techvalley from "../../img/student-outcomes/companies/techValley.png";
import techvalleyBoth from "../../img/student-outcomes/companies/techValleyBoth.png";
import resolved from "../../img/student-outcomes/companies/resolved.png";
import isoftstone from "../../img/student-outcomes/companies/isoftstone.png";
import sharedRealityLab from "../../img/student-outcomes/companies/sharedRealityLab.png";
import mgRobotics from "../../img/student-outcomes/companies/mgRobotics.png";

import Anmol from "../../img/student-outcomes/student-profiles/Anmol.png";
import Jeremy from "../../img/student-outcomes/student-profiles/Jeremy.png";
import Jayde from "../../img/student-outcomes/student-profiles/Jayde.png";
import Yamna from "../../img/student-outcomes/student-profiles/Yamna.png";
import Neli from "../../img/student-outcomes/student-profiles/Neli.png";
import Matthew from "../../img/student-outcomes/student-profiles/Matthew.png"
import Sung from "../../img/student-outcomes/student-profiles/Sung.png"
import Wade from "../../img/student-outcomes/student-profiles/Wade.png"
import Eddy from "../../img/student-outcomes/student-profiles/Eddy.png"
import Moin from "../../img/student-outcomes/student-profiles/Moin.png"
import Aleks from "../../img/student-outcomes/student-profiles/Aleks.png"
import Joseph from "../../img/student-outcomes/student-profiles/Joseph.png"
import Josh from "../../img/student-outcomes/student-profiles/Josh.png"
import Kelvin from "../../img/student-outcomes/student-profiles/Kelvin.png"
import Hanzi from "../../img/student-outcomes/student-profiles/Hanzi.png"

const headerStudentDetails = [
  {
    name: "Anmol",
    title: `${"Waterloo CS Grad \n& Launch Up student"}`,
    profile: Anmol,
    current: "Increased Compensation Full-time Offer",
    companyLogo: pagerDutyColor,
    logoAltText: "PagerDuty Logo",
    gradientStop: "to-[#ED00FF]",
    color: "#ED00FF",
  },
  {
    name: "Jeremy",
    title: `${"Ex-Unicorn SWE \n& Launch Up student"}`,
    profile: Jeremy,
    current: "Increased Compensation Full-time Offer",
    companyLogo: googleColor,
    logoAltText: "Google Logo",
    gradientStop: "to-[#914EFF]",
    color: "#914EFF",
  },
  {
    name: "Edward",
    title: `${"Ryerson Grad \n& Launch Up student"}`,
    profile: Eddy,
    current: "Increased Compensation Full-time Offer",
    companyLogo: ibm,
    logoAltText: "IBM Logo",
    gradientStop: "to-[#0286FF]",
    color: "#0286FF",
  },
  {
    name: "Jayde",
    title: `${"Career-switcher\nEx-RBC, Ex-Scotiabank"}`,
    profile: Jayde,
    current: `${"Four internships \n+ Full-Time Offer"}`,
    companyLogo: techvalleyBoth,
    logoAltText: "TechValley Logo",
    gradientStop: "to-[#5AD0FF]",
    color: "#5AD0FF",
  },
  // {
  //   name: "Yamna",
  //   title: "Waterloo CS grad & Launch Up student",
  //   profile: Yamna,
  //   current: `${"Full-time Software Engineer Offer"}`,
  //   companyLogo: mercuryColor,
  //   gradientStop: "to-[#ED00FF]",
  //   color: "#ED00FF",
  // },
]

const studentDetails = [
  {
    name: "Aleks Valuev",
    profile: Aleks,
    before:`${"Seneca College Grad &\n Student @ Launch Up"}`,
    after:"1st time Internship Achieved via Launch Up",
    companyLogo: [
      { src: threeRm, logoAltText: "3RM Logo" },
    ],
  },
  { 
    name: "Anmol Shah",
    profile: Anmol,
    before:`${"Waterloo CS Grad &\n Student @ Launch Up"}`,
    after:"Increased Full-time Software Engineer Offer",
    companyLogo: [
      { src: pagerDutyColor, logoAltText: "PagerDuty Logo" },
    ]
  },
  {
    name: "Edward Lee",
    profile: Eddy,
    before:`${"Ryerson CS Grad &\n Student @ Launch Up"}`,
    after:"2 Internships & IBM Full-Time Offer",
    companyLogo: [
      { src: bambee, logoAltText: "Bambee Logo" },
      { src: litespace, logoAltText: "Litespace Logo" },
      { src: ibm, logoAltText: "IBM Logo" }
    ],
  },
    {
    name: "Hanzi Li",
    profile: Hanzi,
    before:`${"McGill CS Student &\n Student @ Launch Up"}`,
    after:"6 Internship Experiences Achieved",
    companyLogo: [
      { src: woshi, logoAltText: "Woshi Logo" },
      { src: mgRobotics, logoAltText: "McGill Robotics Logo" },
      { src: sharedRealityLab, logoAltText: "SharedRealityLab Logo" },
      { src: bambee, logoAltText: "Bambee Logo" },
      { src: threeRm, logoAltText: "3RM Logo" },
      { src: isoftstone, logoAltText: "PagerDuty Logo" },
    ]
  },
  {
    name: "Jayde Lim",
    profile: Jayde,
    before:`${"Ex-banker (RBC, Scotia) &\n Student @ Launch Up"}`,
    after:"5 Internships & Full-Time Offer",
    companyLogo: [
      { src: unchainedPartnersColor, logoAltText: "Unchained Partners Logo" },
      { src: erdosVenturesColor, logoAltText: "Erdos Ventures Logo" },
      { src: woshi, logoAltText: "Woshi Logo" },
      { src: bambee, logoAltText: "Bambee Logo" },
      { src: techvalley, logoAltText: "TechValley Logo" },
    ],
  },
  {
    name: "Jeremy Crowe",
    profile: Jeremy,
    before:`${"Ex-Clearco Engineer &\n Student @ Launch Up"}`,
    after:"Full-time Software Engineer, ~$400k+ Offer",
    companyLogo: [
      { src: googleColor, logoAltText: "Google Logo" },
    ],
  },
  {
    name: "Joseph Jun",
    profile: Joseph,
    before:`${"Former Bootcamp Grad &\n Student @ Launch Up"}`,
    after:"2 Internships & Full-Time Software Engineer Offer",
    companyLogo: [
      { src: popular, logoAltText: "Poplar Logo" },
      { src: threeRm, logoAltText: "3RM Logo" },
      { src: implentio, logoAltText: "Implentio Logo" },
    ]
  },
  {
    name: "Josh Date",
    profile: Josh,
    before:`${"Former Bootcamp Grad &\n Student @ Launch Up"}`,
    after:"3 Internships & Full-Time Dev Compensation Increase",
    companyLogo: [
      { src: bambee, logoAltText: "Bambee Logo" },
      { src: erdosVenturesColor, logoAltText: "Erdos Ventures Logo" },
    ],
  },
  {
    name: "Kelvin Chen",
    profile: Kelvin,
    before:`${"Chef / Career Switcher &\n Student @ Launch Up"}`,
    after:"1st time Internship Achieved via Launch Up",
    companyLogo: [
      { src: bambee, logoAltText: "Bambee Logo" },
    ],
  },
  {
    name: "Neli Rashkova",
    profile: Neli,
    before:`${"Former Bootcamp Grad &\n Student @ Launch Up"}`,
    after:"3 Internships Achieved via Launch Up",
    companyLogo: [
      { src: woshi, logoAltText: "Woshi Logo" },
      { src: bambee, logoAltText: "Bambee Logo" },
      { src: implentio, logoAltText: "Implentio Logo" },
    ]
  },
    {
    name: "Matthew Oro",
    profile: Matthew,
    before:`${"McMaster Eng Student &\n Student @ Launch Up"}`,
    after:"4 Internships Achieved via Launch Up",
    companyLogo: [
      { src: unchainedPartnersColor, logoAltText: "Unchained Partners Logo" },
      { src: erdosVenturesColor, logoAltText: "Erdos Ventures Logo" },
      { src: bambee, logoAltText: "Bambee Logo" },
      { src: popular, logoAltText: "Poplar Logo" },
    ],
  },
  {
    name: "Moin Sekander",
    profile: Moin,
    before:`${"UT Mech-Eng Grad &\n Student @ Launch Up"}`,
    after:"2 Internships & Full-time SWE Offer",
    companyLogo: [
      { src: threeRm, logoAltText: "3RM Logo" },
      { src: setori, logoAltText: "Setori Logo" },
      { src: resolved, logoAltText: "reSolved Logo" },
    ],
  },
  {
    name: "Sung Min Sim",
    profile: Sung,
    before:`${"Former Bootcamp Grad &\n Student @ Launch Up"}`,
    after:"1st time Internship Achieved via Launch Up",
    companyLogo: [
      { src: implentio, logoAltText: "Implentio Logo" },
    ],
  },
    {
    name: "Wade Huang",
    profile: Wade,
    before:`${"Waterloo SD Engineering &\n Student @ Launch Up"}`,
    after:"5th internship Achieved via Launch Up",
    companyLogo: [
      { src: AristaColor, logoAltText: "Arista Logo" },
    ],
  },
  {
    name: "Yamna Fatmah",
    profile: Yamna,
    before:`${"Waterloo CS grad &\n Student @ Launch Up"}`,
    after:"Software Engineer Offer with Increased Compensation",
    companyLogo: [
      { src: mercuryColor, logoAltText: "Mercury Logo" },
    ],
  },
]

export { headerStudentDetails, studentDetails };