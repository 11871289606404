import React from 'react';
import Tag from './Tag';
import { jeremy, wade, jayde, sung, matt, neli, yamna } from '../../img/avatars';
import linkedinIcon from '../../img/icons/linkedIn-icon.png';

const recommender = {
  'Jeremy Crowe': jeremy,
  'wade': wade,
  'Neli Rashkova': neli,
  'Matthew Oro': matt,
  'Wade Huang': wade,
  'Yamna Fatmah': yamna,
  'Sung Min Sim': sung,
  'Jayde Lim': jayde,
}

export default function AvatarTestimonial({ details: { tags, name, studentStage, linkedInURL, testimonialBody } }) {
  const renderTags = function() {
    if (tags?.length > 0) {
      return tags.map((tag, index) => {
        return <Tag title={tag} key={index} />
      })
    }
  }

  return(
    <div className="p-5">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <img src={recommender[name]} alt="avatar" className="w-[80px] h-[80px] rounded-full" />
          <div>
            <h4 className="mt-2 ml-5 font-semibold text-xl">{name}</h4>
            <p className="ml-5 mr-1">{studentStage}</p>
          </div>
        </div>
        <a href={`${linkedInURL}`} target='_blank' rel="noreferrer">
          <img src={linkedinIcon} alt="linkedin icon" className="max-h-[30px] mt-2 mr-2 cursor-pointer hover:brightness-75" />
        </a>
      </div>
      <p className="m-2">
        {testimonialBody}
      </p>
      <div className="flex flex-wrap">
      { renderTags() }
      </div>
    </div>
  )
}