import React from 'react';
import logo from '../img/homepage-assets/logo.png';

export default function Nav(props) {
  return (
    <div className="container mx-auto flex justify-between text-white mb-12 md:mb-24 pt-2 px-6 z-10">
      <a href="/" ><img src={logo} alt="Launch Up Logo" className="max-w-xs h-12 mt-6 mx-auto lg:mx-0 z-10" /></a>
      <ul className="flex h-12 mt-6 hidden lg:flex z-10">
          <a href="/student-outcomes"><li className="mt-2 mx-3 pt-1 pb-2 font-medium hover:font-semibold">Student Outcomes</li></a>
          {/* <li className="mx-3 pt-1 pb-2">For Companies</li> */}
          {/* <li className="mx-3 py-2">Our Program</li>  
          <li className="mx-3 py-2">Next Steps</li> */}
          <li className="mx-3">
            <button className="rounded-lg bg-gradient-to-br from-indigo-400 via-indigo-600 
            to-indigo-600 border-[1px] border-indigo-500 px-6 py-2"
            onClick={props.calendlyToggle}
            >
              start now
            </button>
          </li>
      </ul>
    </div>
  )
};
