import React from 'react';
import google from "../../img/homepage-assets/google.png";
import nokia from "../../img/homepage-assets/nokia.png";
import microsoft from "../../img/homepage-assets/microsoft.png";
import clearco from "../../img/homepage-assets/clearco.png";

export default function AboutTeam() {
  return(
    <div className="bg-[#fff] py-[180px] md:pt-[300px] md:pb-[350px] text-black">
      <div className="max-w-[1200px] mx-auto px-[25px] sm:px-[60px] md:px-[25px]">
        <h2 className="text-5xl uppercase leading-[3.6rem] mb-10 text-center">Your Instructors <span className="text-indigo-600">come from</span></h2>
        <div className="grid grid-cols-2 md:grid-cols-4 text-center max-w-[1300px] mx-auto items-center">
          <div className="align-middle mx-auto">
            <img src={google} alt="google" className="h-auto w-[300px] px-5 mt-5" />
          </div>
          <div className="align-middle mx-auto mt-5">
            <img src={clearco} alt="clearco" className="h-auto w-[300px] px-5" />
          </div>
          <div className="align-middle mx-auto mt-5">
            <img src={microsoft} alt="microsoft" className="h-auto w-[300px] px-5" />
          </div>
          <div className="align-middle mx-auto mt-5">
            <img src={nokia} alt="nokia" className="h-auto w-[300px] px-5" />
          </div>
        </div>
      </div>
    </div>
  )
}