const FAQs = [
  {
    question: 'In this program, do I learn full-stack engineering skills or Data Structures & Algorithms interviewing skills?',
    paragraph: 'This entirely depends on your goals! Some folks walk in with a few years of Software Engineering experience and only want to learn to interview well. Others walk in wanting to learn both. We take your goal and your skill level into consideration and then customize a path forward!',
  },
  {
    question: 'I’ve completed a bootcamp already and have hit a plateau with learning. How can I get to the next level?',
    paragraph: 'Launch Up is a great place to be if you want to rise above your plateau. We’ve had many graduates of bootcamps tell us that they didn’t expect to be unemployed for so long, especially when all of the statistics that most bootcamps surface seem to suggest the opposite. The truth is that Software Engineering is a great career, and as such, it isn’t easy to break into this field. The hiring bar is high, and if you can improve beyond basic CRUD apps, and become a real software engineer, the world’s your oyster. The only problem, however, is that it’s also difficult to improve beyond the basics.',
    paragraph2: 'We currently have students who have completed bootcamps and now realize that while the intense introduction to coding was a great starter, there’s much more to be learned before someone is truly job-ready. Our philosophy is to dig deep and not shy away from any set of technical weaknesses. Problem solving, and critical thinking can be improved. Best practices can be learned. And by building together not just amongst students but also with instructors/mentors, you can see the entire work-cycle and git-flow of how people remain productive in a software engineering team!'
  },
  {
    question: 'How long does your program take?',
    paragraph: 'Everyone comes in at a different skill level and with a different set of goals. Most programs like to structure a program length so that there can be a forcing function to close the chapter on a cohort and start a new one. We don’t believe in this. You’re here to achieve your goals, and you walk in with a skill set that can be elementary or advanced. We go as fast as you can go and we push you to have a competitive mindset when it comes to learning.',
  },
  {
    question: 'I’m a fresh beginner, what should I know before starting and how can you help me on my learning journey?',
    paragraph: 'As a beginner, you might be attracted to the idea that software engineering careers can be built in just a few months. It’s important to do research and ask folks who have actually gone to various programs to see what their actual perspectives are like. Many of the folks who we have spoken to and helped out have felt a plateau after their bootcamp education and haven’t been able to improve their skills or kick off their careers. ',
    paragraph2: 'Software Engineering takes a long time, and is hard. We’d love to sell it as an easy decision and argue that massive changes can happen in just weeks, but this simply isn’t true. We’re in the business of actually helping folks to change, and to truly become a Software Engineer. For this to happen, we help you to learn concepts and drill exercises that truly require the exercise of the problem solving faculties of your mind. Bet on your long-term career by looking to make a real change in how you approach your learning, your schedule, and your life!'
  },
  {
    question: 'I’m currently a Software Engineer, New Grad, or Intern. How can the program help me with learning/practicing for interviews?',
    paragraph: 'For Data Structures & Algorithms interviews, it’s simply not enough to attempt to use a memorization approach where you mindlessly try to win the battle by doing thousands of questions. Especially since many of the tougher interviews tend to ask follow-up questions, candidates nowadays have to be ready to make contact with curve-balls thrown at them.',
    paragraph2: 'Launch Up’s program takes folks through the learning of the concepts, individualized homework, pair programming, mock interviews, and virtual onsite simulations in order to eliminate as much room for error as possible, and to practice the performance aspects of interviewing. We help to diagnose the strengths and weaknesses and build a study plan entirely around you!'
  },
];

export default FAQs;