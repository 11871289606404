import tagTypes from "./type/tagTypes";

const testimonials = [
  {
    avatarURI: '/img/avatars/jeremy-avatar.jpeg',
    name: 'Jeremy Crowe',
    studentStage: 'Startup Engineer -> Launch Up -> Google L4 Offer Achieved',
    linkedInURL: 'https://www.linkedin.com/in/jeremyacrowe/',
    testimonialBody: "With a Math & Physics degree and a career in Software Engineering, I’ve been coding for a while but always felt that I could push myself further. In the brief time I’ve been in Launch Up, my skills have increased exponentially. The most immediate results have come in the quality of interviews I’m able to give. I’ve improved not just my problem solving skills, but also my ability to talk about the problem solving process, which is just as important as the solution itself. Launch Up has a great community making it possible to be vulnerable, make mistakes, and grow. If you’re looking to level up your communication or problem solving skills, I would highly recommend Launch Up!",
    widthSize: 1,
    tags: [tagTypes.dreamTier]
  },
  {
    avatarURI: '/img/avatars/neli-avatar.jpeg',
    name: 'Neli Rashkova',
    studentStage: 'From a bootcamp -> Launch Up -> 1st Internship',
    linkedInURL: 'https://www.linkedin.com/in/neli-rashkova/',
    testimonialBody: 'Launch Up is a program that truly takes an innovative approach toward helping individuals begin their journey as a future Software Engineering career. This program is geared for learners of all skill levels; more importantly it was also designed for beginners with no experience like myself. The course material was presented to me at a very beginner level, where I had the opportunity to go at a steady pace and learn the foundations needed. Solidifying core programming before moving on is crucial, similar to having a sturdy foundation before building a house. Youssef created carefully crafted coding projects catered to the material I needed to learn, which helped to target weaknesses and transform them into strengths. This program is different from bootcamps because it customizes a learning plan for each student, their skill level, their schedule, and their career goals & objectives. Additionally, Launch Up provides vital 1:1 sessions for students to help with coding projects, important resume and technical interview prep, and much more. I have felt supported throughout every step of my journey so far. Mike and Youssef go above and beyond as instructors and mentors to prepare students to become knowledgeable and resourceful software developers by teaching us indispensable skills. Not only do they prepare us to become ambitious developers, but they also help to transform us to be better individuals with the right mindset for a successful career in tech and life.',
    widthSize: 2,
    tags: [tagTypes.firstInternship]
  },
  {
    avatarURI: '/img/avatars/matt-avatar.png',
    name: 'Matthew Oro',
    studentStage: 'Mech-Eng Student -> Launch Up -> First 2 Internships',
    linkedInURL: 'https://www.linkedin.com/in/matthew-oro/',
    testimonialBody: 'As a Mech Eng student wanting to switch into Software Engineering, I completed a bootcamp course and followed up on it by learning on my own. Bootcamps help to teach some of the basics, but progress beyond is always difficult. At Launch Up, there’s frequent 1:1s and a sense of a community that supports my long-term goals. The instructors have helped me to improve my full-stack skills by actually taking me through the Pull Request authoring/reviewing process, proper git workflows, and etc. By getting direct feedback on my code, I’ve been able to learn best practices since joining. As well, I believe that the program’s focus on building better habits will help me to propel my career forward!',
    widthSize: 1,
    tags: [tagTypes.firstInternship, tagTypes.secondInternship]
  },
  {
    avatarURI: '/img/avatars/wade-avatar.jpeg',
    name: 'Wade Huang',
    studentStage: 'Waterloo Sys. Des. Student -> Launch Up -> 5th Internship',
    linkedInURL: 'https://www.linkedin.com/in/wade-huang/',
    testimonialBody: 'I’m no stranger to Data Structures & Algorithms interviews as I’ve learned and practiced them for a while now. Even then, Launch Up’s classes helped me to clearly identify all of the things I was missing. In their mock interview classes, my subtle weaknesses in understanding of the material clearly surfaced to the top. Not only that, but there’s also a cohort of people that I benefited from. Watching others perform mocks helped me to learn and realize aspects about my own interviewing skills. I’ve since significantly improved my ability to communicate clearly during the coding rounds. Launch Up has been absolutely amazing!',
    widthSize: 1,
    tags: [tagTypes.multipleOffers]
  },
  {
    avatarURI: '/img/avatars/yamna-avatar.jpeg',
    name: 'Yamna Fatmah',
    studentStage: 'Waterloo CS Grad -> Launch Up -> SWE Offer (increased comp)',
    linkedInURL: 'https://www.linkedin.com/in/yamna-fatmah-790375161/',
    testimonialBody: "Like most engineers, I loathe the interviewing process. During university, I often took the easy way out and returned to a previous company that I enjoyed. However, because of outside circumstances, I finally reached a point where I did not want to just coast through my career. Launch Up has provided me with a community of peers who motivate and challenge me. It has encouraged me to shift my mindset towards a more positive goal of growing my own skills. My comfort with technical interviews has increased immensely and I could not have achieved it without the support of my mentors at Launch Up.",
    widthSize: 1,
    tags: [tagTypes.increasedComp, tagTypes.multipleOffers]
  },
  {
    avatarURI: '/img/avatars/sung-avatar.jpeg',
    name: 'Sung Min Sim',
    studentStage: 'Bootcamp Graduate & Physics B.Sc Student (University of Toronto)',
    linkedInURL: 'https://www.linkedin.com/in/sung-min-sim1/',
    testimonialBody: "I don’t know where I’d be without Launch Up. Being unsatisfied with my university studies at the time, I searched for a certificate program or a bootcamp to expedite my search for a career in Web Development. However, I soon came to realize that bootcamps were too brief and fast-paced while computer science programs were too long and costly. Realizing that Launch Up would be the perfect combination of both, while being in its own category of education, I signed up right away and it has been the best decision of my life. The co-heads of the program, Mike and Youssef, helped me structure my schedule so that I can hold myself accountable for the progress I am making in my learning journey. Also, there is round-the-clock support from them and my fellow coding-mates if I need help and to supplement my learning experience. There are daily coding sessions and learning recaps with others, frequent follow-ups on the projects we complete, and weekly 1-on-1s with the program leaders to share our thoughts and struggles we experienced throughout the week. I can’t wait to see what the future holds for me in the program and the career success that the program will bring!",
    widthSize: 2
  },
  {
    avatarURI: '/img/avatars/jayde-avatar.jpeg',
    name: 'Jayde Lim',
    studentStage: 'Former-Banker -> Launch Up -> First 2 internships',
    objective: 'Aiming to break into tech with a full-time offer',
    linkedInURL: 'https://www.linkedin.com/in/jayde-lim-67930172/',
    testimonialBody: 'As a career switcher who was in banking for almost 8 years, I knew zero coding to start with. I needed to make sure I would learn software engineering to a much more deep level than what the average bootcamps would teach. Every single coaching & learning session from Launch Up has been 100% customized to my current level, learning speed, and goals. There’s no other way that I could have possibly learned as quickly, or deeply, and I’m certain that the biggest success factor in my learning journey has been and will continue to be Launch Up.',
    widthSize: 1,
    tags: [tagTypes.firstInternship, tagTypes.secondInternship]
  },
  // {
  //   avatarURI: '/img/avatars/anmol-avatar.jpeg',
  //   name: 'Anmol Shah',
  //   studentStage: 'Current Software Engineer',
  //   linkedInURL: 'https://www.linkedin.com/in/anmol-shah-223548131/',
  //   testimonialBody: lorem,
  //   widthSize: 1
  // }
  // {
  //   avatarURI: '/img/avatars/michael-avatar.jpeg',
  //   name: 'Michael Santore',
  //   studentStage: 'Current Software Engineer',
  //   objective: 'Learning and improving for full-time roles',
  //   linkedInURL: 'https://www.linkedin.com/in/msantore/',
  //   testimonialBody: lorem,
  //   widthSize: 1
  // },

];

export default testimonials;