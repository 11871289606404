import React, { useState } from "react";
import Nav from "../../components/Nav";
import smallBeginningsLeftBar from "../../img/student-outcomes/small-beginnings.png";
import goalsAchievedLeftBar from "../../img/student-outcomes/goals-achieved-bar-smaller.png";
import productivityWatermark from "../../img/student-outcomes/Productivity.png";
import achievementWatermark from "../../img/student-outcomes/Achievements.png";
import benchSci from "../../img/student-outcomes/companies/benchsci.png";
import mercury from "../../img/student-outcomes/companies/mercury.png";
import stackAdapt from "../../img/student-outcomes/companies/stackadapt.png";
import google from "../../img/student-outcomes/companies/google.png";
import pagerDuty from "../../img/student-outcomes/companies/pagerduty.png";
import erdosVentures from "../../img/student-outcomes/companies/erdosventures.png";
import compassDigital from "../../img/student-outcomes/companies/compassdigital.png";
import unchainedPartners from "../../img/student-outcomes/companies/unchainedpartners.png";
import telus from "../../img/student-outcomes/companies/telus.png";
import BookACall from "../../components/utils/BookACall";
import StudentCard from "./StudentCard.js"
import smallBeginningsBg from "../../img/student-outcomes/small-beginings-bg.png";
import StudentResult from "./StudentResult.js"
import cardTriangle from '../../img/student-outcomes/cardTriangle.png';
import gradientArrow from '../../img/student-outcomes/gradient-arrow.png';
import { headerStudentDetails, studentDetails } from '../../data/student-outcomes/student-results.js';
import faintCircleBg from '../../img/student-outcomes/circle-bg.png';
import beforeAfterBg from '../../img/student-outcomes/before-after-bg.png';

export default function StudentOutcomes({ calendlyToggle }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="font-Raleway bg-[url('./img/bg/student-outcome-bg.png')] bg-no-repeat bg-center bg-top relative min-h-[1250px]">
      <div className="w-full h-24 absolute bottom-0 left-0 opacity-100
      bg-gradient-to-b from-transparent to-[#000100]
      "></div>
      <Nav calendlyToggle={setIsOpen} />
      <div className="container mx-auto xl:flex xl:justify-between text-white relative min-h-full px-4 sm:px-6">
        <div className="mx-auto xl:mx-0 xl:basis-[70%] max-w-screen-md z-10 text-center xl:text-left max-w-md">
          <h1 className="font-Raleway text-[60px] xl:text-[80px] font-extrabold text-white uppercase">Student <br/> Outcomes</h1>
          <p className="font-medium text-lg">We're an intensive program that obsesses over real progress and real results.</p>
        </div>
        <div className="mx-auto relative left-0 max-w-[657px] mt-10 md:mt-[100px] mb-[-120px] xl:mt-0 xl:mb-0 xl:mx-0 xl:left-0">
          <div className="bg-black/50 rounded-xl md:rounded-3xl backdrop-blur-lg">
            <StudentCard students={headerStudentDetails} />
          </div>
          {/* <div className="bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] from-slate-900 via-slate-800 to-slate-700">
            <div className="">
              <img src="" />
              <h2>name</h2>

            </div>
          </div> */}
        </div>
      </div>
      <div className="mx-auto w-8">
        {/* <img src={downArrow} alt="down arrow"/> */}
      </div>
      <div className="container mx-auto flex mt-[140px] relative">
        <img src={smallBeginningsLeftBar} className="absolute left-0" alt="left divider for small beginnings section"/>
        <img src={productivityWatermark} className="absolute left-20 top-[250px]" alt="Productivity watermark design" />
        <img src={smallBeginningsBg} className="absolute w-[90%] top-56 -right-48 blur-md" alt="vague background color"/>
        <div className="ml-20 mt-[145px] pb-5">
          <h2 className="text-white text-[40px] xl:text-[64px] font-extrabold uppercase">Small Beginnings,</h2>
          <h2 className="bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] from-pink-800 via-fuchsia-400 to-sky-800 inline-block text-transparent bg-clip-text text-[40px] xl:text-[64px] font-extrabold uppercase">Big Results.</h2>
          <div className="max-w-[620px] mt-[30px] mb-[30px]">
            <p className="text-white font-medium text-lg mb-3">
              We focus on having a very selective group of intense serious learners
              and we help them to make real transformations.
              Our mission is to help folks far beyond just landing a job.
              We're there for at least 4 years to help them grow into the engineer they want to become.
            </p>
            <p className="text-white font-medium text-lg">
              In 2023, mass tech layoffs was making it difficult for everyone to find jobs. However, our students
              managed to absolutely outperform their expectations, with a student to work opportunity ratio
              of nearly 1:3.
            </p>
            <p className="text-launchup-pink font-medium text-lg mt-4">
              Here are some of the great results that our students achieved:
            </p>
          </div>
        </div>
      </div>
      
      {/* Before/After heading image */}
      <div className="container flex items-center justify-center max-w-full mx-auto px-2.5 overflow-visible">
        <div className="w-full max-w-[1600px] xl:w-5/6 rounded-xl rounded-xl p-0.5 bg-gradient-to-tr from-[#1b3748] via-[#0b131c] to-[#351a3f] z-10 relative">
          <img src={beforeAfterBg} className="absolute -left-72 -bottom-36 md:-bottom-96" alt="faint background highlight" />
          <div className="bg-[#10111c] px-2 pb-2 rounded-xl relative">
            <img src={faintCircleBg} className="absolute left-[35%] top-[10%] blur-3xl" alt="faint background highlight at top" />
            <img src={faintCircleBg} className="absolute left-[55%] bottom-[10%] blur-3xl" alt="faint background highlight at bottom" />
            <div className="flex flex-col w-full overflow-x-auto group px-3 pt-5 rounded-xl">
              <div className={`relative w-[826px] md:w-[1000px] lg:w-full h-[65px] xl:h-[100px] bg-gradient-to-r from-launchup-pink to-[#4675FD] flex rounded-t-3xl mb-8`}>
                <div className="bg-[#090B12] rounded-ss-3xl w-5"></div>
                <div className="w-[45%] md:w-1/2 bg-[#090B12] relative flex items-center justify-center">
                  <div className="text-lg xl:text-2xl font-semibold text-white pr-5">BEFORE JOINING</div>
                  <div className="h-full absolute bottom-0 -right-[22px] xl:-right-[34px]">
                    <img src={cardTriangle} className="h-full" alt="arrow for current student status" />
                    <img src={gradientArrow} className="absolute h-9 xl:h-16 bottom-[15px] xl:bottom-[18px] right-3" alt="gradient arrow on the black arrow"/>
                  </div>
                </div>
                <div className="flex w-[55%] flex justify-center items-center">
                  <div className="text-lg xl:text-2xl font-semibold text-white">AFTER JOINING</div>
                </div>
              </div>
              <div className="w-full">
                {
                  studentDetails.map((item, index) => {
                    return (
                      <StudentResult student={item} />
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto flex relative">
        <img src={goalsAchievedLeftBar} className="absolute left-0 top-[-180px] sm:top-[-140px] md:top-[-100px] lg:top-[-20px] xl:top-0 z-2" alt="left divider for goals achieved section"/>
        <img src={achievementWatermark} className="absolute left-20 top-[420px]" alt="Achievements watermark design" />
        <div className="ml-20 mt-[70px] sm:mt-[140px] md:mt-[200px] lg:mt-[280px] xl:mt-[270px] m-w-[550px]">
          <h2 className="text-white text-[40px] xl:text-[64px] font-extrabold uppercase">Goals <span className="bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] from-indigo-700 via-blue-300 to-violet-900 inline-block text-transparent bg-clip-text ">Achieved</span></h2>
          <p className="text-white font-medium text-lg mb-3">Our students have received offers from a wide range of tech companies,
          ranging from big tech to hot startups.
          </p>
        </div>
      </div>
      <div className="container relative mx-auto">
      <div className="pt-[200px] z-10 bg-[url('./img/student-outcomes/offers-companies.png')] min-h-[1000px] bg-no-repeat bg-center bg:cover xl:bg-contain">
        <div className="xl:w-[1000px] mx-auto">
          <div className="flex justify-between z-10 items-center mt-[160px] mb-20 mx-10 hidden xl:flex">
            <img src={mercury} alt="mercury company" className="max-h-[80px]" />
            <img src={stackAdapt} alt="stack adapt company" className="max-h-[60px]" />
            <img src={google} alt="google company" className="max-h-[50px]" />
            <img src={pagerDuty} alt="pagerduty company" className="max-h-[40px]" />
            <img src={benchSci} alt="benchsci company" className="max-h-[40px]" />
          </div>  

          <div className="flex justify-center items-center hidden xl:flex">
            <img src={erdosVentures} alt="erdos company" className="max-h-[70px] mx-6"/>
            <img src={telus} alt="telus company" className="max-h-[50px] mx-6"  />
            <img src={compassDigital} alt="compass digital company" className="max-h-[50px] mx-6" />
            <img src={unchainedPartners} alt="unchained company" className="max-h-[80px] mx-6" />
          </div>
          <div className="flex justify-center items-center xl:hidden mt-20">
            <img src={mercury} alt="mercury company" className="max-h-[80px] mx-4 sm:mx-10 text-center" />
            <img src={stackAdapt} alt="stack adapt company" className="max-h-[60px] mx-4 sm:mx-10" />
          </div>
          <div className="flex justify-center items-center xl:hidden mt-10">
            <img src={pagerDuty} alt="pagerduty company" className="max-h-[40px] mx-4 sm:mx-10" />
            <img src={google} alt="google company" className="max-h-[50px] mx-4 sm:mx-10 text-center" />
          </div>
          <div className="flex justify-center items-center xl:hidden mt-10">
            <img src={benchSci} alt="benchsci company" className="max-h-[40px] mx-4 sm:mx-10" />
            <img src={telus} alt="telus company" className="max-h-[50px] mx-4 sm:mx-10 text-center" />
          </div>
          <div className="flex justify-center items-center xl:hidden mt-10">
            <img src={erdosVentures} alt="erdos company" className="max-h-[40px] mx-4 sm:mx-10" />
            <img src={compassDigital} alt="compass digital company" className="max-h-[50px] mx-4 sm:mx-10 text-center" />
          </div>
        </div>
      </div>  
              
      </div>
      <BookACall
        isOpen={isOpen}
        calendlyToggle={setIsOpen}
      />
    </div>
  )
};