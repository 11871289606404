import React from 'react';
import testimonials from '../../data/testimonial';
import AvatarTestimonial from '../../components/utils/AvatarTestimonial';

export default function WhatFolksAreSaying() {
  return (
    <div className="bg-[#fff] pt-[100px] pb-[200px] text-black bg-gradient-to-br from-white via-[#fcfcfc] to-[#deefff]">
      <div className="max-w-[1440px] sm:px-10 mx-auto">
        <h2 className="text-5xl uppercase leading-[3.6rem] mb-10 text-center"><span className="text-indigo-600">What</span> Folks are saying</h2>
        <div className="flex flex-wrap">
          {
            testimonials.map((testimonial, index) => {
              let widthSizeClassName = testimonial.widthSize === 1 ? 'lg:basis-[calc(33%-10px)]' : 'lg:basis-[calc(66%-10px)]';
               
              return (
                <div className={`${widthSizeClassName} m-[5px] h-inherit border-[1px] border-[#dbeffd] rounded-xl`} key={index}>
                  <AvatarTestimonial details={testimonial} />
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}