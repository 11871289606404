import React from 'react';
import tagTypes from '../../data/type/tagTypes';

export default function Tag(props) {
  let tagTypeColor;
  switch(props.title) {
    case tagTypes.dreamTier:
      tagTypeColor = 'bg-[#35944e] border-[#29703c]';
      break;
    case tagTypes.firstJob:
      tagTypeColor = 'bg-[#3e5cb0] border-[#354f97]';
      break;
    case tagTypes.increasedComp:
      tagTypeColor = 'bg-[#e86b30] border-[#e65a19]';
      break;
    case tagTypes.firstInternship:
      tagTypeColor = 'bg-[#067BB6] border-[#04699C]';
      break;
    case tagTypes.secondInternship:
      tagTypeColor = 'bg-[#EE5E3F] border-[#DA563A]';
      break;
    default:
      tagTypeColor = 'bg-[#43A21C] border-[#899220]';
      break;
  }

  return (
    <div className={`${tagTypeColor} text-white py-[2px] px-2 rounded-xl my-1 mr-2 shrink-0 border-[1px] border-dashed text-sm font-light`}>
      {props.title}
    </div>
  )
}