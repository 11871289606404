import React from 'react';
import Nav from '../../components/Nav';
import HeroTextArea from './HeroTextArea';

export default function Header({ calendlyToggle }){
  return (
    <div className="bg-[url('./img/homepage-assets/bg_stars.png')] relative">
      <div className="w-full h-24 absolute bottom-0 left-0 opacity-100
      bg-gradient-to-b from-transparent to-[#000100]
      "></div>
      <Nav calendlyToggle={calendlyToggle} />
      <HeroTextArea calendlyToggle={calendlyToggle} />
    </div>
  )
}