import React from 'react';
import bgBluePunch from '../../img/bg/blue-punch.png';
import bgSquareRotated from '../../img/shape-assets/rotated-square-black.png';
import bgBlackCircle from '../../img/shape-assets/circle-black.png';

export default function ContactUs(props) {
  return (
    <div className="text-center pb-[360px] text-white bg-[#000100] z-10 relative px-[20px]">
      <div className="container pt-[150px] max-w-[1400px] mx-auto">
        <img src={bgBluePunch} alt="background radial gradient" className="absolute left-[-450px] bottom-0 z-20" />
        <div className="relative max-w-[305px] text-center mx-auto">
          <h2 className="text-5xl uppercase leading-[60px] mb-10 z-10 relative">
            Contact Us
          </h2>
          <img src={bgSquareRotated} alt="background square" className="absolute bottom-[-40px] left-[-60px] z-5" />
          <img src={bgBlackCircle} alt="background circle" className="hidden md:block absolute top-[-30px] right-[-60px] z-5" />
        </div>
        <p className="text-white max-w-[1000px] mx-auto leading-8 text-lg">Most of the questions you might have are much more easily answered 
        through a <span onClick={props.calendlyToggle} className="text-indigo-500 cursor-pointer">video meeting</span>! But if you've got questions that you'd like to ask through email instead, feel free to 
        email <a href="mailto: info@launchup.io" className="text-indigo-500">info@launchup.io</a>
        </p>
      </div>
    </div>
  )
}