import React from 'react';
import skillsIcons from '../../img/homepage-assets/skills.png';
import listStarIcon from '../../img/icons/list-star-icon.png';

function StarIcon() {
  return <img src={listStarIcon} alt="star icon" className="max-h-[12px] mr-2 mt-[6px]" />
}

export default function WhatYouLearn(props) {
  return (
    <div className="text-lg text-white container pb-0 px-[25px] md:px-[100px] pt-[50px] mb-[120px] lg:mb-[250px] mx-auto max-w-[1240px] text-center border-[1px] rounded-xl border-[#ccc] bg-gradient-to-tl from-[#211746] via-[#0F0B1E] to-[#000100]">
      <h2 className="text-[44px] leading-[52px] lg:text-5xl uppercase leading-[3.6rem] mb-10">What You will <span  className="text-indigo-600">Learn</span></h2>
      <p className="font-extralight">Everyone who enters our program learns by doing sophisticated projects. 
      Most of our students receive the first internship through our extensive network with startups.
      At Launch Up, what you learn isn't to just to dabble with frameworks. We help you to become a Software Engineer that can solve complex problems in a real-world setting.
      </p>
      <div className="lg:flex mt-2">
        <div className="relative max-w-[460px] mx-auto">
          <img src={skillsIcons} alt="skills" className="lg:max-w-[550px] relative lg:left-[-115px] lg:top-[80px]" />
        </div>
        <div className="mt-10">
          <ul className="text-left font-extralight">
            <li className="mb-8">
              <h3 className="text-xl font-semibold mb-2 text-left">1. Problem Solving with Code</h3>
              <ul className="font-extralight ml-4 md:ml-0">
                <li className="flex mb-1"><StarIcon />Master javascript concepts + core programming skills</li>
                <li className="flex mb-1"><StarIcon />Build Programming intuition and practice having a better learning mindset</li>
                {/* <li className="flex mb-1"><StarIcon />Learn to integrate multiple core skills to solve more complex problems</li> */}
              </ul>
            </li>

            <li className="mb-8">
              <h3 className="text-xl font-semibold mb-2 text-left">2. Complex Full-Stack Development</h3>
              <ul className="font-extralight ml-4 md:ml-0">
                <li className="flex mb-1">
                  <StarIcon />
                  Deep dive into all necessary languages, frameworks, technologies, and 
                  workflows (Node.js, React.js, Redux, Typescript, SQL, Git, ClickUp, and etc.)
                </li>
                <li className="flex mb-1">
                  <StarIcon />
                  Build complex robust projects, getting PR reviews from instructors and industry
                  Software Engineers
                </li>
                {/* <li className="flex mb-1">
                  <StarIcon />
                  Prepare for full-stack engineering interviews
                </li> */}
              </ul>
            </li>

            <li className="mb-8">
              <h3 className="text-xl font-semibold mb-2 text-left">3. Data Structures & Algorithms</h3>
              <ul className="font-extralight ml-4 md:ml-0">
                <li className="flex mb-1">
                  <StarIcon />
                  Learn Data Structures & Algorithms with Python (in-depth)
                </li>
                <li className="flex mb-1">
                  <StarIcon />
                  Regular pair-programming sessions and drills on questions using a technical round interviewing software.
                </li>
                <li className="flex mb-1">
                  <StarIcon />
                  Regular recorded mock-interviewing to help build mental stamina and get used to real-life 
                  interviewing settings.
                </li>
                {/* <li className="flex mb-1">
                  <StarIcon />
                  Resume crafting and behavioural interview practice
                </li> */}
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}