import React, { useState } from 'react';
import cardTriangle from '../../img/student-outcomes/cardTriangle.png';
import doubleArrow from '../../img/student-outcomes/double-arrow.png';
import goLeft from '../../img/student-outcomes/go-left.png';
import goRight from '../../img/student-outcomes/go-right.png'; 

const StudentResult = ({ student }) => {
  const [visibleLogos, setVisibleLogos] = useState([0, 2]);

  const handleArrowClick = (value) => {
    setVisibleLogos([visibleLogos[0]+value, visibleLogos[1]+value])
  }
  return (
    <div>
      <div className="text-white flex flex-col items-center mb-4 w-[826px] md:w-[1000px] lg:w-full">
        <div className={`relative w-full md:w-[95%] bg-[#080A10]/50 flex rounded-l-2xl rounded-r-xl justify-between h-20 2xl:h-[120px]`}>
          <div className="w-[45%] md:w-[48%] bg-[#080A10] rounded-l-xl relative flex items-center justify-center">
            <div className="flex items-center w-full z-20 h-full p-3.5 2xl:p-5">
              <div className="flex items-center gap-2.5 2xl:gap-6 w-1/2 mr-2">
                <img src={student.profile} className="w-[50px] 2xl:w-20 h-[50px] 2xl:h-20 rounded-full" alt="student profile" />
                <div className="text-sm md:text-xl 2xl:text-2xl font-semibold text-white">{student.name}</div>
              </div> 
              <div className="text-xs md:text-base 2xl:text-xl text-[#848588] whitespace-pre-line tracking-">{student.before}</div>
              <img src={doubleArrow} className="absolute -right-6 2xl:-right-8 z-20 w-[60px] 2xl:w-[100px]" alt="gradient arrow on the black arrow" />
            </div>
            <div className="h-full absolute bottom-0 -right-[28px] 2xl:-right-[41px]">
              <img src={cardTriangle} className="h-full" alt="arrow for current student status" />
            </div>
          </div>
          <div className="flex w-1/2 justify-between items-center md:pl-10 2xl:pl-20">
            <div className="text-sm md:text-base 2xl:text-xl font-semibold text-white whitespace-pre-line w-[170px] md:w-[200px] 2xl:w-[257px] pr-2.5">{student.after}</div>
            <div className="flex relative">
              <div className="absolute -left-1 top-1/2 transform -translate-y-1/2">
                {
                  visibleLogos[0] !== 0 &&
                    <button onClick={() => handleArrowClick(-1)}>
                      <img src={goLeft} alt="left arrow" />
                    </button>
                }
              </div>
              {
                student.companyLogo.slice(visibleLogos[0], visibleLogos[1]).map((logo, index) => (
                  <div key={index} className="m-2 w-24 2xl:w-40 h-[60px] 2xl:h-24 rounded-lg bg-[#F7F9F9] flex items-center justify-center p-1 2xl:p-2.5">
                    <img src={logo.src} className="w-full m-8" alt={logo.logoAltText} />
                  </div>
                ))
              }
              <div className="absolute -right-1 top-1/2 transform -translate-y-1/2">
                {
                  visibleLogos[1] < student.companyLogo.length && 
                    <button onClick={() => handleArrowClick(1)}>
                      <img src={goRight} alt="right arrow" />
                    </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentResult;