import React from 'react';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { useState } from 'react';
import Icon from "../../components/utils/Icon";
import FAQs from '../../data/FAQs';
import bgBluePunch from '../../img/bg/blue-punch.png';
import bgBluePunch2 from '../../img/bg/blue-punch2.png';
import bgSquareRotated from '../../img/shape-assets/rotated-square-black.png';
import bgBlackCircle from '../../img/shape-assets/circle-black.png';

export default function FAQ() {
  const [open, setOpen] = useState(0);
 
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const halfwayIndex = Math.ceil(FAQs.length/2);
  const firstHalfFAQs = FAQs.slice(0, halfwayIndex);
  const secondHalfFAQs = FAQs.slice(halfwayIndex);

  const renderFAQs = function(arr, startingIndex = 0) {
    return (
      arr.map((item, index) => {
        const nthItem = index + 1 + startingIndex;
        const ulList = function() {
          if (item.hasOwnProperty('ulBody')){
            item.ulBody.map(listContent => {
              return <li key={`list-${index+startingIndex}`}>{listContent}</li>
            })
          } else {
            return false;
          }
        }
        
        return (
          
          <div className="mx-[10px]" key={`accordion-${index + startingIndex}`}>
            <Accordion 
              open={open === nthItem} onClick={() => handleOpen(nthItem)}
              icon={<Icon id={nthItem} open={open} />}
              animate={customAnimation}
              className={`z-50 text-left my-4 pt-4 ${ open === nthItem && 'pb-10'} px-9 border-[1px] border-[#ccc] rounded-xl bg-gradient-to-r from-[#141414] via-[#143042] to-[#141414] cursor-pointer`}
            >
              <AccordionHeader><h3 className="text-left text-lg font-semibold">{item.question}</h3></AccordionHeader>
              <AccordionBody>
                <div>
                  <p className="mb-2 mt-4">
                    {item.paragraph}
                  </p>
                  { item.paragraph2 && <p className="mb-2 mt-4">{item.paragraph2}</p>}
                  { ulList() && <ul className="list-disc ml-10">{ulList()}</ul> }
                </div>
              </AccordionBody>
            </Accordion>
          </div>
        )
      })
    )
  }

  const customAnimation = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
  };

  return (
    <div className="text-center pb-[120px] md:pb-[320px] text-white bg-[#000100] z-10 relative">
      <div className="h-6 bg-gradient-to-r from-blue-600 via-blue-400 to-blue-300"></div>
      <div className="container pt-[180px] max-w-[1400px] mx-auto">
        <img src={bgBluePunch} alt="background gradient" className="absolute left-[-450px] bottom-0 z-10" />
        <img src={bgBluePunch2} alt="background gradient" className="absolute right-0 top-0 z-10" />
        <div className="relative max-w-[125px] text-center mx-auto">
          <h2 className="text-5xl uppercase leading-[60px] mb-10 z-10 relative">
            FAQ
          </h2>
          <img src={bgSquareRotated} alt="background square" className="absolute top-[-20px] left-[-20px] z-5" />
          <img src={bgBlackCircle} alt="background circle" className="absolute bottom-[-30px] right-[-40px] opacity-75 z-5" />
        </div>
        
        <div className="md:flex z-50">
          <div className="basis-1/2 mt-10 md:flex md:flex-col">
            { renderFAQs(firstHalfFAQs, 0) }
          </div>
          <div className="basis-1/2 md:mt-10 md:flex md:flex-col">
            { renderFAQs(secondHalfFAQs, halfwayIndex) }
          </div>
        </div>
      </div>
    </div>
  )
}