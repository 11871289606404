import React from 'react';
import bgGradientLeft from '../../img/homepage-assets/radial-gradient-left-core-message.png';
import bgGradientRight from '../../img/homepage-assets/radial-gradient-right-core-message.png';

export default function CoreMessage() {
  // Any centered section with h2 and paragraph
  return (
    <div className="container mx-auto text-center my-[80px] lg:my-[140px] py-[140px] px-[25px] max-w-[1100px] text-white z-10 relative">
      <h2 className="text-[44px] leading-[52px] lg:text-5xl uppercase lg:leading-[3.6rem] mb-10 relative z-10">
        <span className="text-indigo-600">Tutorials</span> aren't enough.<br/>
        <span className="text-indigo-600">Bootcamps</span> aren't enough.
      </h2>
      <div className="font-light text-lg relative z-10">
        <p className="mb-4">Basic full-stack programming is not enough in today’s work environment. 
          Many beginners, bootcamp graduates, and current full-time engineers 
          have high ambitions to further their career, but are stuck without knowing how 
          to get to the next level.</p>
        <p>We’re here to mix the best parts of theoretical CS concepts along with the more 
          deeply-integrated work-simulations and mock-interviews. We take a long-term approach 
          that involves multiple years of mentoring, a focus on guided independent learning, an 
          overarching theme of improving mindset + habits, and strategic goal-setting to help you 
          achieve your goals.</p>
      </div>
      <img src={bgGradientLeft} alt="background gradient" className="z-1 absolute left-[-400px] top-[-200px]" />
      <img src={bgGradientRight} alt="background gradient" className="z-1 absolute right-[0px] bottom-[-160px]" />
    </div>
  )
};