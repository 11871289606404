import React from "react";
import profileShadow from '../../img/student-outcomes/profile-shadow.png'
import cardTriangle from '../../img/student-outcomes/cardTriangle.png'

const StudentCard = ({ students }) => {
  return (
    <div className="flex flex-col gap-2 md:gap-4 p-2 md:p-4 w-full">
      {students.map((student, index) => (
        <div className="h-24 sm:h-36" key={index}>
          <div className={`relative w-full h-full bg-gradient-to-r from-[#080a10] ${student.gradientStop} flex rounded-lg opacity-90 flex justify-between`}>
          {/* left side of the profile (inside of black arrow) */}
            <div className="w-1/2 sm:w-[45%] bg-[#080A10] rounded-lg relative opacity-90">
              <div className="py-3 pl-3 sm:pr-2 h-full flex items-center gap-x-2 sm:gap-x-3 z-20 relative">
                <div className="relative w-[50px] sm:w-1/3">
                  <img src={student.profile} className="rounded-full z-20 relative" alt="student profile" />
                  <img src={profileShadow} className="absolute -top-2 sm:-top-3 -right-6 sm:-right-8 h-16 sm:h-24 z-10" alt="purple profile shadow" />
                </div>
                <div className="flex flex-col w-2/3">
                  <div className="text-base sm:text-2xl font-bold">{student.name}</div>
                  <div className="text-[11px] sm:text-sm font-white opacity-50 leading-4 sm:leading-5 whitespace-pre-line tracking-tight">{student.title}</div>
                </div>
              </div>
              <div className="h-full absolute bottom-0 -right-[29px] sm:-right-[46px]">
                <img src={cardTriangle} className="h-full" alt="arrow for current student status" />
                <div className="z-10 absolute bottom-[30px] sm:bottom-[23.5px] -right-1 sm:right-2">
                  <svg className="h-[34px] sm:h-[98px]" xmlns="http://www.w3.org/2000/svg" width="34" viewBox="0 0 34 98" fill="none">
                    <path opacity="0.5" d="M1 1L30.9057 43.2198C33.3587 46.6829 33.3587 51.3171 30.9057 54.7802L1 97" stroke={student.color} stroke-width="2" stroke-linecap="round"/>
                  </svg>
                </div>
              </div>
            </div>
            {/* right side of the profile */}
            <div className="flex w-[55%] justify-between items-center pl-10 sm:pl-16 sm:pr-2 sm:py-2">
              <div className="text-xs sm:text-base md:text-lg font-semibold w-[55%] leading-4 sm:leading-6 whitespace-pre-line">{student.current}</div>
              <div className="m-2 w-14 sm:w-24 h-14 sm:h-24 rounded-lg bg-white flex items-center justify-center p-2">
                <img src={student.companyLogo} className="w-full" alt={student.logoAltText} />
              </div>
            </div>
          </div>   
        </div>     
      ))}
    </div>
  )
}

export default StudentCard;