import React, { useState } from 'react';
import mikePic from '../../img/homepage-assets/mike-group.png';
import youzziePic from '../../img/homepage-assets/youssef-group.png';
import BlueBtnLink from '../../components/utils/BlueBtnLink';

export default function AboutTeam() {
  const [focusedMember, setFocusedMember] = useState('mike')

  const isYoussef = focusedMember === 'youssef';
  const isMike = focusedMember === 'mike';

  const changeTeam = (event) => {
    if(event.currentTarget.id === 'mike') {
      setFocusedMember('mike')
    } else if(event.currentTarget.id === 'youssef') {
      setFocusedMember('youssef')
    }
  }

  return(
    <div className="bg-[#fff] py-[150px] md:pt-[300px] md:pb-[150px] text-black">
      <div className="max-w-[1200px] mx-auto px-[25px] sm:px-[60px] md:px-[25px]">
        <h2 className="text-5xl uppercase leading-[3.6rem] mb-10 text-center">About the <span className="text-indigo-600">team</span></h2>
        <div className="md:flex md:justify-between md:items-center pt-[50px]"> 
          <div className="md:basis-[30%] mt-[-80px] relative">
            <h3 
              className={`text-center md:text-left text-2xl leading-[3rem] border-b-[1px] border-black font-medium cursor-pointer hover:text-blue-500 ease-in-out duration-250 ${isMike ? 'text-blue-400' : 'text-black'}`} 
              onClick={changeTeam}
              id="mike"
            >
              Mike Kang 
              <div className={`w-2 h-2 bg-blue-400 rounded-full absolute top-5 right-0 animate-pulse ease-in-out duration-250 ${isYoussef && 'hidden'}`}></div>
            </h3>
            <h3 
              className={`text-center md:text-left text-2xl leading-[3rem] font-medium cursor-pointer hover:text-blue-500 ease-in-out duration-250 ${isYoussef ? 'text-blue-400' : 'text-black'} `}
              onClick={changeTeam}
              id="youssef"
            >
              Youssef Zayed 
              <div className={`w-2 h-2 bg-blue-400 rounded-full absolute top-[68px] right-0 animate-pulse ease-in-out duration-250 ${isMike && 'hidden'}`}></div>
            </h3>
          </div>

          <div className={`ml-[-170px] ${isYoussef && 'hidden'}`}>
            <img src={mikePic} alt="Mike" className="md:w-[90%] mx-auto pl-[20px]"/>
          </div>

          <div className={`ml-[-170px] ${isMike && 'hidden'}`}>
            <img src={youzziePic} alt="Youssef" className="md:w-[90%] mx-auto pl-[20px]"/>
          </div>

          {/* Mike Content */}
          <div className={`text-center md:text-left md:basis-[45%] ease-in-out duration-250 ${isYoussef && 'hidden'}`}>
            <h4 className="text-center md:text-left font-semibold text-lg mb-4">CEO & Co-founder</h4>
            <p className="text-[#808CA5] mb-4 font-medium">
              Before Launch Up, Mike was an 
              <span className="text-blue-500"> Engineering Manager @ <a href="https://clear.co" target="_blank" rel="noreferrer">Clearco</a></span>, 
              a Series-C Fintech Unicorn, based in Toronto. Previous to that, he was 
              a Software Engineer at multiple startups working across the fullstack for various products/services. 
              In the past, Mike was also a <span className="text-blue-500">Product Manager @ Quartermaster</span>, a home & neighbourhood mobile & web app 
              startup, and was a <span className="text-blue-500">Lead Instructor @ Brainstation</span>, a web dev bootcamp. Mike has 
              always been an entrepreneur. He was also <span className="text-blue-500">Co-founder & CEO @ Nerdstudy</span>, which raised <span className="text-blue-500">$1 million</span> in 
              equity funding, garnering <span className="text-blue-500">40k</span> users. Mike is incredibly 
              passionate about helping others to make a successful career switch into tech.</p>
            <BlueBtnLink href="https://www.linkedin.com/in/mikekang23/" text="LinkedIn" />
          </div>
          
          {/* Youssef Content */}
          <div className={`text-center md:text-left md:basis-[45%] ease-in-out duration-250 ${isMike && 'hidden'}`}>
            <h4 className="text-center md:text-left font-semibold text-lg mb-4">Head of Learning & Co-founder</h4>
            <p className="text-[#808CA5] mb-4 font-medium">Before Launch Up, Youssef was a <span className="text-blue-500">Co-founder</span> of a real-estate startup, <span className="text-blue-500">Seamless Estate</span>. 
              Previous to this, he worked as a Software Engineering Intern @ <span className="text-blue-500">Google, Clearco, 
              Microsoft, and Nokia.</span> Youssef brings a pedagogical approach to learning how to 
              code and is particularly good with helping folks to achieve deeper understanding. As a 
              person who felt that coding never came naturally to himself, he brings empathy and experience 
              to the table. Fun fact: Youssef and Mike started Launch Up as a result of mentoring and 
              guiding Mike’s fiancée, who was also making a career switch into Software Engineering. </p>
            <BlueBtnLink href="https://www.linkedin.com/in/youssefzayed/" text="LinkedIn" />
          </div>
        </div>
      </div>
    </div>
  )
}