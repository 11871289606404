import React from "react";
import { useState, useEffect } from "react";
import { PopupModal } from "react-calendly";
import gmeet from "../../img/avatars/gmeet-avatar.png"

export default function BookACall(props) {
  const [isBrowser, setIsBrowser] = useState(false);
  const [isGmeetHighlighted, setGmeetHighlighted] = useState(false);

  useEffect(() => {
   setIsBrowser(typeof window !== "undefined");
  }, []);

  return isBrowser ? (
      <div>
        <button
          className={`rounded-2xl bg-gradient-to-br from-indigo-400 via-indigo-600 
          to-indigo-600 border-[1px] border-indigo-500 pl-[30px] pr-[85px] py-[11px] text-white 
          font-medium fixed right-[20px] bottom-[20px] drop-shadow-lg z-50 cursor-pointer ${isGmeetHighlighted ? 'opacity-100' : 'opacity-0'} transition-all ease-in-out duration-300`}
          onClick={() => props.calendlyToggle(true)}
          onMouseEnter={() => setGmeetHighlighted(true)}
          onMouseLeave={() => setGmeetHighlighted(false)}
        >
          <div className="flex items-center">
            <div className="text-left"> 
              Schedule a Video<br/>
              Chat with Mike
            </div>
          </div>
        </button>
        <img 
          src={gmeet}
          className={`w-[60px] h-[60px] rounded-full ml-4 bg-white border-2 border-indigo-400 z-[60] fixed right-[30px] bottom-[25px] cursor-pointer
                      opacity-100 transition-all ease-in-out duration-300
                    `}
          alt="gmeet"
          onClick={() => props.calendlyToggle(true)}
          onMouseEnter={() => setGmeetHighlighted(true)}
          onMouseLeave={() => setGmeetHighlighted(false)}
        />
        <PopupModal
          url="https://calendly.com/launchup"
          onModalClose={() => props.calendlyToggle(false)}
          open={props.isOpen}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById("root")}
        />
      </div>
  ) : null;
}