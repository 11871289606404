import React from 'react';
import starIcon from '../../img/icons/star-icon.png';
import planet from '../../img/homepage-assets/planet.png';
import console from '../../img/homepage-assets/console.gif';
import Typewriter from 'typewriter-effect';

export default function HeroTextArea(props) {
  return (
    <div className="container mx-auto flex flex-row text-white relative min-h-full ">
      <div className="lg:basis-[70%] max-w-screen-md z-10 text-center lg:text-left">
        <div className="px-[20px]">
          <h1 className="text-[44px] leading-[52px] md:text-5xl font-medium mt-5 mb-10 uppercase lg:leading-[61px] md:mx-0">
            Learn Software <br/>  Engineering through <br/>
            <span className="text-indigo-500 lg:text-indigo-600">
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .deleteAll()
                  .typeString('internships')
                  .pauseFor(1500)
                  .deleteAll()
                  .typeString('pair-programming')
                  .pauseFor(1500)
                  .deleteAll()
                  .typeString('long-term mentors')
                  .pauseFor(1500)
                  .deleteAll()
                  .typeString('work experience')
                  .pauseFor(1500)
                  .start();
              }}
            />
            </span>
          </h1>
          <p className="mb-10 font-light text-lg">In order to find your edge in the Software Engineering 
          job market, you need two things:
          <span className="text-indigo-400 lg:text-indigo-400"> Knowledge</span> & 
          <span className="text-indigo-400 lg:text-indigo-400"> Work Experience</span>. 
          At Launch Up, we train you to be strong developers. We have multiple startup partnerships where many 
          of our students have found their first, second, and even third internships from. Simple CRUD app tutorials
          aren't enough if you want a job. We teach you the hard stuff through real codebases.</p>
          <button 
            className="rounded-lg bg-gradient-to-br from-indigo-400 via-indigo-600 hover:from-indigo-400 hover:via-indigo-500 hover:to-indigo-600
            to-indigo-600 border-[1px] border-indigo-500 px-9 py-3 mb-[100px] md:mb-[150px]"
            onClick={props.calendlyToggle}
          >
            Find Out More
          </button>
        </div>
        <div className="flex justify-center lg:justify-start pl-[20px]">
          <div className="md:flex md:flex-row md:pb-[70px] text-left md:justify-start min-w-[240px] lg:min-w-[700px] ">  
            <div className="md:basis-1/3 flex items-center pb-7">
              <img src={starIcon} alt="star icon" className="max-h-[30px] mr-4" />
              We're partnered with  <br />several startups
            </div>
            <div className="md:basis-1/3 flex items-center pb-7 md:pl-6">
              <img src={starIcon} alt="star icon" className="max-h-[30px] mr-4" />
              Learn full-stack via<br /> real codebases
            </div>
            <div className="md:basis-1/3 flex items-center pb-7 md:pl-8">
              <img src={starIcon} alt="star icon" className="max-h-[30px] mr-4" />
              Get continuous long<br /> term support
            </div>
          </div>
        </div>
      </div>
      <img src={planet} alt="earth" className="max-w-[700px] md:max-w-[800px] absolute right-[0px] md:right-[60px] lg:right-[0] top-[100px] md:top-[-60px] lg:top-[-80px] z-1 opacity-30 lg:opacity-100 lg:max-w-[900px]" />
      <img src={console} alt="console" className="absolute right-20 bottom-[100px] lg:right-0 lg:bottom-[150px] z-10 invisible lg:visible lg:max-w-[260px] md:opacity-80 max-w-[300px] hover:w-[320px] ease-in-out duration-300 " />
    </div>
  )
};