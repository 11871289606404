import React from 'react';
import diagnosticIcon from '../../img/icons/diagnostic-icon.png';
import roadmapIcon from '../../img/icons/roadmap-icon.png';
import trialPeriodIcon from '../../img/icons/trial-period-icon.png';
import codeIcon from '../../img/icons/code-icon.png';
import progressAchieveIcon from '../../img/icons/progress-achieve-icon.png';
import mentorIcon from '../../img/icons/mentor-instructor-icon.png';

export default function HowProgramWorks() {
  // Any centered section with h2 and paragraph
  return (
    <div className="pb-[180px] bg-white relative text-white bg-gradient-to-tl from-[#d2defc] via-[#0d59ff] to-[#0043d5]">
      <div className="flex w-full bg-gradient-to-b from-[#000100] to-[#222] justify-center z-1 h-[600px] bg-[url('./img/homepage-assets/multiple-coding-collage.png')]">
      </div>
      
      <div className="container mx-auto max-w-[880px]">
        <h2 className="text-center text-5xl uppercase leading-[3.6rem] mb-10 bg-white z-100 p-4 top-[-45px] 
        relative md:rounded-lg text-black bg-gradient-to-r via-[#F6F8FF] from-white to-[#D7E1FF]">
          How our program works
        </h2>
        {/* <div className="flex justify-between">
          <div className="mb-3 w-[120px] sm:mx-0">
            <img src={diagnosticIcon} alt="diagnostic icon" className="mx-auto w-[70px]" />
            <p className="mt-[10px] text-center font-medium">1. Diagnostic Sessions</p>
          </div>
          <div className="mb-3 w-[120px]">
            <img src={roadmapIcon} alt="roadmap icon" className="mx-auto w-[70px]" />
            <p className="mt-[10px] text-center font-medium">2. Create a personalized roadmap</p>
          </div>
          <div className="mb-3 w-[120px]">
            <img src={trialPeriodIcon} alt="trial period icon" className="mx-auto w-[70px]" />
            <p className="mt-[10px] text-center font-medium">3. Trial period for fit assessment</p>
          </div>
          <div className="mb-3 w-[120px]">
            <img src={codeIcon} alt="code icon" className="mx-auto w-[70px]" />
            <p className="mt-[10px] text-center font-medium">4. Learn, Code, Reflect, Repeat</p>
          </div>
          <div className="mb-3 w-[120px]">
            <img src={progressAchieveIcon} alt="progress achieve icon" className="mx-auto w-[70px]" />
            <p className="mt-[10px] text-center font-medium">5. Interview + Achieve your goal</p>
          </div>
          <div className="mb-3 w-[120px]">
            <img src={mentorIcon} alt="mentor instructor icon" className="mx-auto w-[70px]" />
            <p className="mt-[10px] text-center font-medium">6. Stay in our community as a mentor!</p>
          </div>
        </div> */}
        <div className="sm:flex my-[40px] items-center mx-[25px]">
          <div className="mb-3 w-[120px] mx-auto sm:mx-0">
            <img src={diagnosticIcon} alt="diagnostic icon" className="mx-auto w-[70px]" />
            <p className="mt-[10px] text-center font-medium">1. Diagnostic Sessions</p>
          </div>
          <div className="text-center sm:text-left sm:ml-[25px] sm:ml-[40px] w-full">
            <p className="mb-1 w-full">We take time to figure out your skill level in each of the three core domains.</p>
            <ul className="text-left list-disc ml-5">
              <li>Problem Solving with Code</li>
              <li>Complex Full-stack Development</li>
              <li>Data Structures & Algorithms Concepts + Interviewing</li>
            </ul>
          </div>
        </div>

        <div className="sm:flex my-[40px] items-center mx-[25px]">
          <div className="mb-3 w-[120px] mx-auto">
            <img src={roadmapIcon} alt="roadmap icon" className="mx-auto w-[70px]" />
            <p className="mt-[10px] text-center font-medium">2. Your roadmap</p>
          </div>
          <div className="text-center sm:text-left sm:ml-[25px] sm:ml-[40px] w-full">
            <p className="mb-1">We purposefully have a small cohort size to focus on creating great results. No cookie cutter methodology. Your pace and your roadmap is personalized to you.</p>
          </div>
        </div>

        <div className="sm:flex my-[40px] items-center mx-[25px]">
          <div className="mb-3 w-[120px] mx-auto">
            <img src={trialPeriodIcon} alt="trial period icon" className="mx-auto w-[70px]" />
            <p className="mt-[10px] text-center font-medium">3. Trial period assessment</p>
          </div>
          <div className="text-center sm:text-left sm:ml-[25px] sm:ml-[40px] w-full">
            <p className="mb-1">We made the application process extremely simple. After you chat with us,
            Start a free trial period where we can assess your learning habits and performance.
            If you pass the trial round, we'll extend an offer to join our cohort as a student!
            </p>
          </div>
        </div>

        <div className="sm:flex my-[40px] items-center mx-[25px]">
          <div className="mb-3 w-[120px] mx-auto">
            <img src={codeIcon} alt="code icon" className="mx-auto w-[70px]" />
            <p className="mt-[10px] text-center font-medium">4. Learn by Coding</p>
          </div>
          <div className="sm:ml-[25px] sm:ml-[40px] w-full">
            <p className="mb-1">Start with core concepts. Bootcamp level of core skills not nearly enough.
            Once you're performing well, we'll include you into projects. We've got a great network of startups partnered with us.
            Oftentimes, you'll be able to get you first internship experience directly through the opportunities we create.
            </p>
          </div>
        </div>

        <div className="sm:flex my-[40px] items-center mx-[25px]">
          <div className="mb-3 w-[120px] mx-auto">
            <img src={progressAchieveIcon} alt="progress achieve icon" className="mx-auto w-[70px]" />
            <p className="mt-[10px] text-center font-medium">5. Interview for Jobs</p>
          </div>
          <div className="text-center sm:text-left sm:ml-[25px] sm:ml-[40px] w-full">
            <p className="mb-1">When it's time for you to interview, there's full-stack skills that you needed for some jobs
            and Algorithms skills that you need for other jobs. Worry not! We've helped to place folks into great companies like Google, Mercury, PagerDuty, and etc.
            </p>
          </div>
        </div>

        <div className="sm:flex my-[40px] items-center mx-[25px]">
          <div className="mb-3 w-[120px] mx-auto">
            <img src={mentorIcon} alt="mentor instructor icon" className="mx-auto w-[70px]" />
            <p className="mt-[10px] text-center font-medium">6. Become a mentor!</p>
          </div>
          <div className="text-center sm:text-left sm:ml-[25px] sm:ml-[40px] w-full">
            <p className="mb-1"> Once you've achieved your job, you're probably looking to not just increase your body of knowledgeable
            but to also assume a more senior role in the ecosystem. One great way is to become a mentor to help folks in the same way
            that you received help on your way up!
            </p>
          </div>
        </div>
      </div>
    </div>
  )
};