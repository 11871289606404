import React, { useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import Icon from "../../components/utils/Icon";
import bgRadialLeft from "../../img/homepage-assets/radial-gradient-left-core-message.png";
import bgRadialRight from "../../img/homepage-assets/radial-gradient-right-core-message.png";
import tinyTree from "../../img/homepage-assets/beginner-tree.png";
import smallTree from "../../img/homepage-assets/small-tree.png";
import mediumTree from "../../img/homepage-assets/medium-tree.png";
import largeTree from "../../img/homepage-assets/large-tree.png";

export default function WhoFor() {
  const [open, setOpen] = useState(0);
 
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const customAnimation = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
  };


  // Any centered section with h2 and paragraph
  return (
    <div className="text-lg container mx-auto text-center px-[25px] pt-[100px] pb-[320px] max-w-[1000px] text-white bg-[#000100] z-10 relative">
      <img src={bgRadialLeft} alt="background gradient" className="z-1000 absolute left-[-400px] top-[-260px] z-1" />
      <img src={bgRadialRight} alt="background gradient" className="z-1000 absolute right-[0] bottom-[0px] z-1" />
      <h2 className="text-[44px] leading-[52px] lg:text-5xl uppercase leading-[3.6rem] mb-10 relative">
        <span className="text-indigo-600">Who</span> is it for?<br/>
      </h2>
      <div className="font-light z-10 relative">
        <p className="mb-4">Whether you’ve never written a single line of code before, or you are 
        already a Software Engineer making a great salary with proficiency in dev skills, there’s 
        always room for career enrichment. Here are a few archetypes of people that we help. People 
        do not always fit squarely into one bracket or another, and so sometimes we find a mix of 
        traits, and of course we’re here to help everyone unique to their own set of skills and 
        experiences.</p>
      </div>
      <div className="mt-10  max-w-[calc(100% - 40px)]">
        <Accordion 
          open={open === 1} onClick={() => handleOpen(1)}
          icon={<Icon id={1} open={open} />}
          animate={customAnimation}
          className="text-left my-4 py-7 px-9 border-[1px] border-[#ccc] rounded-xl bg-gradient-to-r from-[#141414] via-[#143042] to-[#141414] cursor-pointer"
        >
          <AccordionHeader><h3 className="text-xl text-left font-semibold">Beginners / Students</h3></AccordionHeader>
          <AccordionBody className="flex">
            <div className="sm:basis-3/4 mr-2 lg:mr-10 pb-5 text-lg">
              <p className="mb-2 mt-4">Perhaps you’re completely new to programming. You’ve thought about joining a bootcamp 
                in the past, but realized that a few months of studying will likely only result in a 
                basic introduction. You want a comprehensive education that goes beyond the 
                all-too-familiar building of simple apps. Some of your are:
              </p>
              <ul className="list-disc ml-10">
                <li>University students (CS, STEM, Non-STEM)</li>
                <li>Graduates that are now career-switching</li>
              </ul>
            </div>
            <div className="basis-1/4 hidden md:block">
              <img src={tinyTree} alt="tiny tree" className="mx-auto max-h-[70px] mt-[80px] pl-[16px] cursor-pointer z-1" />
            </div>
          </AccordionBody>
        </Accordion>
        <Accordion 
          open={open === 2} onClick={() => handleOpen(2)}
          icon={<Icon id={2} open={open} />}
          animate={customAnimation}
          className="text-left my-4 py-7 px-9 border-[1px] border-[#ccc] rounded-xl bg-gradient-to-r from-[#141414] via-[#143042] to-[#141414] cursor-pointer"
        >
          <AccordionHeader><h3 className="text-xl text-left font-semibold">Bootcamp Graduates</h3></AccordionHeader>
          <AccordionBody className="flex">
            <div className="sm:basis-3/4 mr-2 lg:mr-10 pb-5 text-lg">
              <p className="mb-2 mt-4">You’ve committed several months to bootcamp and have graduated. You’re capable of writing some code, 
              but find yourself not sufficiently moving beyond the basic CRUD app. You’re having a hard time getting the attention of 
              recruiters, and coding by yourself or with your friends (usually from the same bootcamp) isn’t enough to teach you best-practices 
              in the industry. You’re willing to put the time and effort in, but need a program to help you through more advanced concepts, 
              along with data structure and algorithm concepts + interviewing skills.
              </p>
            </div>
            <div className="basis-1/4 hidden md:block">
              <img src={smallTree} alt="small tree" className="mx-auto max-h-[130px] mt-[50px] pl-[16px] cursor-pointer z-1" />
            </div>
          </AccordionBody>
        </Accordion>
        <Accordion 
          open={open === 3} onClick={() => handleOpen(3)}
          icon={<Icon id={3} open={open}/>}
          animate={customAnimation}
          className="relative text-left my-4 py-7 px-9 border-[1px] border-[#ccc] rounded-xl bg-gradient-to-r from-[#141414] via-[#143042] to-[#141414] cursor-pointer"
        >
          <AccordionHeader><h3 className="text-xl text-left font-semibold">Current Interns</h3></AccordionHeader>
          <AccordionBody className="flex">
            <div className="sm:basis-3/4 mr-2 lg:mr-10 pb-5 text-lg">
              <p className="mb-2 mt-4">Perhaps you’ve got a few internships under your belt already. Even then, making the jump to your 
              dream company can still be very difficult. Weaknesses in full-stack development skills during the more “practical” interviews 
              and under-developed understanding/practice with data structure and algorithms can make it particularly tough for students 
              to break through plateaus. We help you focus on:
              </p>
              <ul className="list-disc ml-10">
                <li>Identifying weaknesses (in full-stack engineering and/or in interviewing)</li>
                <li>Learning, drilling, and reflecting on the material for you to do well in your next opportunity</li>
              </ul>
            </div>
            <div className="basis-1/4 mt-[-20px] hidden md:block">
              <img src={mediumTree} alt="medium tree" className="mx-auto max-h-[220px] mt-[40px] pl-[16px] cursor-pointer z-1" />
            </div>
          </AccordionBody>
        </Accordion>
        <Accordion 
          open={open === 4} onClick={() => handleOpen(4)}
          icon={<Icon id={4} open={open} />}
          animate={customAnimation}
          className="text-left my-4 py-8 px-9 border-[1px] border-[#ccc] rounded-xl bg-gradient-to-r from-[#141414] via-[#143042] to-[#141414] cursor-pointer"
        >
          <AccordionHeader><h3 className="text-xl text-left font-semibold"><span className="hidden sm:inline">Current</span> Software Engineers</h3></AccordionHeader>
          <AccordionBody className="flex">
            <div className="sm:basis-3/4 mr-2 lg:mr-10 pb-5 text-lg">
              <p className="mb-2 mt-4">You’re currently working as a software engineer. You’re probably making a good six figure salary already 
              and have learned to build applications, as a full-stack stack engineer. You want to move forward with your education to fill in the 
              missing CS fundamentals and interviewing skills. At the same time, perhaps you don't want to aimlessly grind out questions and want to
              make sure that you're improving in a systematic way. We can help with:
              </p>
              <ul className="list-disc ml-10">
                <li>Resume re-drafting, along with recorded video calls to help you improve communication skills</li>
                <li>Data Structures & Algorithms (concepts + pair programming + mock interviews)</li>
                <li>Helping to manage/balance the application phase along with the learning phase. Negotiating with offers, and etc!</li>
              </ul>
            </div>
            <div className="basis-1/4 mt-[0px] hidden md:block">
              <img src={largeTree} alt="large tree" className="mx-auto ml-[-10px] mt-[40px] h-[270px] pl-[16px] cursor-pointer z-1" />
            </div>
          </AccordionBody>
        </Accordion>
      </div>
    </div>
  )
};